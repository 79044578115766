<template >
      <v-container class="background">
              <v-col style="color: #fff">
                <span @click="irAHome()" class="breadcrumb">Inicio</span> / <span class="breadcrumb">Contratos</span>   
            </v-col>

               <v-row>
                 <v-col cols="12" sm="" md="" lg="3" style="text-align:center;">
                     <v-card elevation="1" color="white" @click="irAgregarNuevoContrato" >
                         <v-card-title class="justify-center">Agregar nuevo contrato</v-card-title> 
                        <v-card-text>
                           <v-btn text >
                             <v-icon>
                               mdi-file-sign
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                 </v-col>
                 <v-col cols="12" sm="" md="" lg="3" style="text-align:center">
                     <v-card elevation="1" color="white" >
                         <v-card-title class="justify-center">Anexo Contrato</v-card-title> 
                        <v-card-text>
                           <v-btn text >
                             <v-icon>
                                mdi-chart-timeline
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="" md="" lg="3" style="text-align:center">
                     <v-card elevation="1" color="white" >
                          <v-card-title class="justify-center">Gestionar Contrato</v-card-title> 
                        <v-card-text>
                           <v-btn text>
                             <v-icon>
                               mdi-hand-coin
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                 </v-col>
                 <v-col cols="12" sm="" md="" lg="3" style="text-align:center">
                    <v-card elevation="1" color="white" >
                          <v-card-title class="justify-center">Simular Informe</v-card-title> 
                        <v-card-text>
                           <v-btn color="primary" block>
                             <v-icon>
                               mdi-domain
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                 </v-col>
               </v-row>


    </v-container>
</template>

<script>


  export default {
    name: 'Home',
    data: () => ({
      contrato: false,
   subItemsContrato: [
        {
          titulo: 'Familiar', icono: 'mdi-human-male-female-child', action: 'Familiar'
        },
        {
          titulo: 'Colectivo', icono: 'mdi-crowd', action: 'Colectivo'
        },
        {
          titulo: 'Area Protegida', icono: 'mdi-alert', action: 'AreaProtegida'
        },
      ],


      anexoContratos: [
        ['Familiares Anexo', 'mdi-human-male-female-child'],
        ['Colectivo Anexo', 'mdi-crowd'],
      ],

      gestionarContratos:[
        { titulo: 'Contratos Guardados', icono: 'mdi-file-plus',  action: 'contratos-guardados'},
        { titulo: 'Estados Contratos', icono:'medi-badge-account', action: 'estados-contratos'}
      ],

      itemsContrato: [
       { 
         titulo: 'Agregar Nuevo Contrato',
         icono: 'mdi-file-plus'
       },
       {
         titulo: 'Anexo Contrato',
         icono: 'mdi-finance'
       },
       {
         titulo: 'Gestionar Contrato',
         icono: 'mdi-file-cog',
       },
         {
         titulo: 'Simular Tarifa',
         icono: 'mdi-finance'
       },
      ],
       selectedItem: 1,
      items: [
        { text: 'Simular Tarifa', icon: 'mdi-clock', action: 'simular-tarifa' },
     
      ],
    }),
    components: {

    },
    methods: {
     irAHome(){
         this.$router.push({ path: '/home2' })
     }, 
      abrir(item){
          switch (item) {
            case 'Contrato':
                console.log(item);
                this.contrato = !this.contrato;
              break;
            case 'Informes':
                console.log(item);
              break;
            case 'Comisiones':
                console.log(item);
              break;
            case 'Empresas':
                console.log(item);
                alert('empre')
              break;
                case 'gestionar-contrato':
                console.log(item);
              break;
            default:
              break;
          }

      },

    menuActionClick(action) {
      switch (action) {
        case 'AreaProtegida':
          alert('Area')
        break;
              case 'Familiar':
         
         this.$router.push({ path: 'familiar' })
        break;
              case 'Colectivo':
         alert('Colectivo')
        break;
        case 'contratos-guardados':
         this.$router.push({ path: 'contratos-guardados' })
        
        break;
        case 'estado-contratos':
         this.$router.push({ path: 'estado-contratos' })
         console.log('Estado');
        break;
      
        default:
          break;
      }
    },
    irAgregarNuevoContrato(){
      this.$router.push({ path: 'agregar-contrato' })
    }
    }
  }
</script>

<style scoped>
.background{
    background: rgb(34, 78, 201) !important;
}

.breadcrumb:hover{
    padding: 1px 0px 1px 0px;
    border-radius: 5px;
    background: rgb(70, 108, 214) !important; 
}
</style>