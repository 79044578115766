<template>
  <v-card>
    <v-toolbar dark height="40" color="indigo darken-1">
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-n16">Modificar</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="red" small @click="$emit('cerrar-modal')"
        ><v-icon>mdi-alpha-x</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-text class="ma-0 pa-0" v-if="!cargando_datos">
      <!-- TITULAR -->
      <v-col cols="12" class="pa-0 ma-0">
        <v-col cols="12" class="d-flex justify-center">
          <h1 style="font-size: 42px" class="mt-2">Titular</h1>
        </v-col>
        <v-form ref="formularioTitular">
          <v-data-table
            :items="items_titular"
            :headers="headers_titular"
            class="ma-0 pa-0"
            hide-default-footer
          >
            <template v-slot:[`item.primer_nombre`]="{ item }">
              <v-col v-if="item.editar" style="width: 200px">
                <v-text-field
                  class="mt-2"
                  dense
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  v-model="item.primer_nombre"
                  v-if="item.editar"
                >
                </v-text-field>
              </v-col>
              <div v-else>
                {{ item.primer_nombre }}
              </div>
            </template>

            <template v-slot:[`item.segundo_nombre`]="{ item }">
              <v-col v-if="item.editar" style="width: 200px">
                <v-text-field
                  class="mt-2"
                  dense
                  v-model="item.segundo_nombre"
                  v-if="item.editar"
                >
                </v-text-field>
              </v-col>
              <div v-else>
                {{
                  item.segundo_nombre == null
                    ? "No Registra"
                    : item.segundo_nombre
                }}
              </div>
            </template>

            <template v-slot:[`item.primer_apellido`]="{ item }">
              <v-col v-if="item.editar" style="width: 200px">
                <v-text-field
                  class="mt-2"
                  dense
                  v-model="item.primer_apellido"
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  v-if="item.editar"
                >
                </v-text-field>
              </v-col>
              <div v-else>
                {{ item.primer_apellido }}
              </div>
            </template>

            <template v-slot:[`item.segundo_apellido`]="{ item }">
              <v-col v-if="item.editar" style="width: 200px">
                <v-text-field
                  class="mt-2"
                  dense
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  v-model="item.segundo_apellido"
                  v-if="item.editar"
                >
                </v-text-field>
              </v-col>
              <div v-else>
                {{ item.segundo_apellido }}
              </div>
            </template>

            <template v-slot:[`item.profesion`]="{ item }">
              <v-text-field
                class="mt-2"
                dense
                v-model="item.profesion"
                v-if="item.editar"
              >
              </v-text-field>
              <div v-else>
                {{ item.profesion }}
              </div>
            </template>

            <template v-slot:[`item.fecha_nacimiento`]="{ item }">
              <div v-if="item.editar" style="width: 110px">
                <v-menu
                  v-if="item.editar"
                  v-model="item.picker_fecha_nacimiento"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  dense
                  outlined
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.fecha_nacimiento"
                      dense
                      class="mt-2"
                      append-icon="mdi-calendar"
                      @click:append="item.picker_fecha_nacimiento = true"
                      readonly
                      :rules="[(v) => !!v || 'Campo Requerido']"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    min="1923-01-01"
                    locale="es"
                    v-model="item.fecha_nacimiento"
                    @input="item.picker_fecha_nacimiento = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div v-else>
                {{ item.fecha_nacimiento }}
              </div>
            </template>

            <template v-slot:[`item.genero`]="{ item }">
              <v-col v-if="item.editar" class="input-general">
                <v-select
                  v-if="item.editar"
                  class="mt-2"
                  :items="generos"
                  item-text="genero"
                  item-value="id"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  v-model="item.genero"
                  dense
                ></v-select>
              </v-col>
              <div v-else>
                {{ validarGenero(item.genero) }}
              </div>
            </template>

            <template v-slot:[`item.estado_civil`]="{ item }">
              <div v-if="item.editar" style="width: 120px">
                <v-select
                  class="mt-2"
                  :items="estados_civiles"
                  item-text="estado_civil"
                  item-value="id"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  v-model="item.estado_civil"
                  dense
                ></v-select>
              </div>

              <div v-else>
                {{ validarEstadoCivil(item.estado_civil) }}
              </div>
            </template>

            <template v-slot:[`item.profesion`]="{ item }">
              <div v-if="item.editar" class="input-general">
                <v-select
                  class="mt-2"
                  :items="profesiones"
                  item-text="profesion"
                  item-value="id"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  v-model="item.profesion"
                  dense
                ></v-select>
              </div>

              <div v-else>
                {{ validarProfesion(item.profesion) }}
              </div>
            </template>

            <template v-slot:[`item.correo`]="{ item }">
              <v-col v-if="item.editar" style="width: 200px">
                <v-text-field
                  dense
                  class="mt-2"
                  :rules="[
                    (v) =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      'E-mail debe ser valido',
                  ]"
                  v-model="item.correo"
                ></v-text-field>
              </v-col>
              <div v-else>
                {{ item.correo }}
              </div>
            </template>

            <template v-slot:[`item.direccion_completa`]="{ item }">
              <div v-if="item.editar">
                <a @click="abrirModalDireccion(true, item)">
                  {{ item.direccion.direccion_completa }}</a
                >
              </div>
              <div v-else>
                {{ item.direccion.direccion_completa }}
              </div>
            </template>

            <template v-slot:[`item.telefono`]="{ item }">
              <div v-if="item.editar" style="width: 110px">
                <v-text-field
                  type="number"
                  class="mt-2"
                  dense
                  v-model="item.telefono"
                ></v-text-field>
              </div>
              <div v-else>
                {{ item.telefono == null ? "No registra" : item.telefono }}
              </div>
            </template>

            <!-- REVISAR CELULAR TITULAR Y AGREGAR MODAL DIRECCION -->
            <template v-slot:[`item.estrato`]="{ item }">
              <div v-if="item.editar">
                <v-text-field
                  type="number"
                  class="mt-2"
                  dense
                  v-model="item.estrato"
                ></v-text-field>
              </div>
              <div v-else>
                {{ item.estrato }}
              </div>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-row class="justify-center">
                <v-btn
                  @click="editarTitular(item)"
                  v-if="!item.editar"
                  class="ml-n2"
                  color="primary"
                  small
                  icon
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >

                <v-btn
                  v-if="item.editar"
                  :disabled="item.guardando_modificaciones"
                  color="red"
                  @click="cancelarEditarTitular(item)"
                  small
                  icon
                  ><v-icon>mdi-cancel</v-icon></v-btn
                >

                <v-btn
                  v-if="item.editar"
                  :loading="item.guardando_modificaciones"
                  color="success"
                  @click="guardarModificacionesTitular(item)"
                  small
                  icon
                  ><v-icon>mdi-check</v-icon></v-btn
                >
              </v-row>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
      <v-divider></v-divider>
      <!-- BENEFICIARIOS -->
      <v-col cols="12">
        <v-col cols="12" class="d-flex justify-center">
          <h1 style="font-size: 42px" class="mt-2">Beneficiarios</h1>
        </v-col>
        <v-form ref="formularioBen">
          <v-data-table
            :items="items_beneficiarios"
            :headers="headers_beneficiarios"
          >
            <template v-slot:[`item.primer_nombre`]="{ item }">
              <v-col v-if="item.editar" class="input-general">
                <v-text-field
                  class="mt-2"
                  dense
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  v-model="item.primer_nombre"
                >
                </v-text-field>
              </v-col>

              <div v-else>
                {{ item.primer_nombre }}
              </div>
            </template>

            <template v-slot:[`item.segundo_nombre`]="{ item }">
              <v-col v-if="item.editar" class="input-general">
                <v-text-field class="mt-2" dense v-model="item.segundo_nombre">
                </v-text-field>
              </v-col>

              <div v-else>
                {{
                  item.segundo_nombre == null
                    ? "No Registra"
                    : item.segundo_nombre
                }}
              </div>
            </template>

            <template v-slot:[`item.primer_apellido`]="{ item }">
              <v-col v-if="item.editar" class="input-general">
                <v-text-field
                  class="mt-2"
                  dense
                  v-model="item.primer_apellido"
                  :rules="[(v) => !!v || 'Campo Requerido']"
                >
                </v-text-field>
              </v-col>
              <div v-else>
                {{ item.primer_apellido }}
              </div>
            </template>

            <template v-slot:[`item.segundo_apellido`]="{ item }">
              <v-col v-if="item.editar" class="input-general">
                <v-text-field
                  class="mt-2"
                  dense
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  v-model="item.segundo_apellido"
                >
                </v-text-field>
              </v-col>
              <div v-else>
                {{ item.segundo_apellido }}
              </div>
            </template>

            <template v-slot:[`item.fecha_nacimiento`]="{ item }">
              <div v-if="item.editar" style="width: 120px">
                <v-menu
                  v-model="item.picker_fecha_nacimiento"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  dense
                  outlined
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.fecha_nacimiento"
                      dense
                      class="mt-2"
                      append-icon="mdi-calendar"
                      @click:append="item.picker_fecha_nacimiento = true"
                      readonly
                      :rules="[(v) => !!v || 'Campo Requerido']"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    min="1923-01-01"
                    locale="es"
                    v-model="item.fecha_nacimiento"
                    @input="item.picker_fecha_nacimiento = false"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div v-else>
                {{ item.fecha_nacimiento }}
              </div>
            </template>

            <template v-slot:[`item.genero`]="{ item }">
              <v-col v-if="item.editar" class="input-general">
                <v-select
                  class="mt-2"
                  :items="generos"
                  item-text="genero"
                  item-value="id"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  v-model="item.genero"
                  dense
                ></v-select>
              </v-col>

              <div v-else>
                {{ validarGenero(item.genero) }}
              </div>
            </template>

            <template v-slot:[`item.direccion_completa`]="{ item }">
              <div v-if="item.editar">
                <a @click="abrirModalDireccion(false, item)">
                  {{ item.direccion.direccion_completa }}</a
                >
              </div>
              <div v-else>
                {{ item.direccion.direccion_completa }}
              </div>
            </template>

            <template v-slot:[`item.telefono`]="{ item }">
              <div v-if="item.editar" style="width: 110px">
                <v-text-field
                  type="number"
                  class="mt-2"
                  dense
                  v-model="item.telefono"
                ></v-text-field>
              </div>
              <div v-else>
                {{ item.telefono == null ? "No registra" : item.telefono }}
              </div>
            </template>

            <template v-slot:[`item.celular`]="{ item }">
              <div v-if="item.editar" style="width: 110px">
                <v-text-field
                  type="number"
                  class="mt-2"
                  dense
                  v-model="item.celular"
                ></v-text-field>
              </div>
              <div v-else>
                {{ item.celular }}
              </div>
            </template>

            <!-- REVISAR CELULAR TITULAR Y AGREGAR MODAL DIRECCION -->
            <template v-slot:[`item.estrato`]="{ item }">
              <div v-if="item.editar">
                <v-text-field
                  type="number"
                  class="mt-2"
                  dense
                  v-model="item.estrato"
                ></v-text-field>
              </div>
              <div v-else>
                {{ item.estrato }}
              </div>
            </template>

            <template v-slot:[`item.eps`]="{ item }">
              <div v-if="item.editar" class="input-general">
                <v-autocomplete
                  v-model="item.eps"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="items_eps"
                  item-text="epsnom"
                  item-value="epscod"
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  dense
                ></v-autocomplete>
              </div>
              <div v-else>
                {{ validarEps(item.eps) }}
              </div>
            </template>

            <template v-slot:[`item.correo`]="{ item }">
              <v-col v-if="item.editar" style="width: 200px">
                <v-text-field
                  dense
                  :rules="[
                    (v) =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      'E-mail debe ser valido',
                  ]"
                  v-model="item.correo"
                ></v-text-field>
              </v-col>
              <div v-else>
                {{ item.correo }}
              </div>
            </template>

            <template v-slot:[`item.regimen`]="{ item }">
              <div v-if="item.editar" class="input-general">
                <v-select
                  v-model="item.regimen"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="regimenes"
                  item-text="regimen"
                  item-value="id"
                  :rules="[(v) => !!v || 'Campo requerido']"
                  dense
                ></v-select>
              </div>
              <div v-else>
                {{ validarRegimen(item.regimen) }}
              </div>
            </template>

            <template v-slot:[`item.parentesco`]="{ item }">
              <div v-if="item.editar">
                <v-select
                  :items="parentescos"
                  v-model="item.parentesco"
                  item-text="parentesco"
                  item-value="ID_Paren"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  :rules="[(v) => !!v || 'Campo requerido']"
                ></v-select>
              </div>
              <div v-else>
                {{ validarParentesco(item.parentesco) }}
              </div>
            </template>

            <template v-slot:[`item.asegurabilidad_texto`]="{ item }">
              <div
                v-if="item.editar"
                @click="abrirModalAsegurabilidad('beneficiario', item)"
              >
                <a>
                  <div
                    v-for="(enfermedad, index) in item.asegurabilidad_texto"
                    :key="index"
                  >
                    <span v-if="index == item.asegurabilidad_texto.length - 1">
                      {{ enfermedad }}
                    </span>
                    <span v-else>
                      {{ enfermedad + ", " }}
                    </span>
                  </div>
                </a>
              </div>
              <div v-else>
                <div
                  v-for="(enfermedad, index) in item.asegurabilidad_texto"
                  :key="index"
                >
                  <span v-if="index == item.asegurabilidad_texto.length - 1">
                    {{ enfermedad }}
                  </span>
                  <span v-else>
                    {{ enfermedad + ", " }}
                  </span>
                </div>
              </div>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-row class="justify-center">
                <v-btn
                  v-if="!item.editar"
                  @click="editarBeneficiario(item)"
                  class="ml-n2"
                  color="primary"
                  small
                  icon
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >

                <v-btn
                  v-if="item.editar"
                  :disabled="item.guardando_modificaciones"
                  color="red"
                  @click="cancelarEditarBeneficiario(item)"
                  small
                  icon
                  ><v-icon>mdi-cancel</v-icon></v-btn
                >

                <v-btn
                  v-if="item.editar"
                  :loading="item.guardando_modificaciones"
                  color="success"
                  @click="guardarModificacionesBeneficiario(item)"
                  small
                  icon
                  ><v-icon>mdi-check</v-icon></v-btn
                >
              </v-row>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-card-text>
 
      <div
      v-else 
        cols="12"
        style="
          display: flex;
          align-items: center;
  
          justify-content: center;
          margin-top: 250px;
        "
      >
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
   

    <v-dialog v-model="modal_direccion" width="900">
      <ModalDireccion
        v-if="modal_direccion"
        :json_direccion="json_direccion"
        @guardar_direccion="guardarDireccion"
        @cerrar="modal_direccion = false"
      />
    </v-dialog>

    <template v-if="modal_asegurabilidad">
      <v-dialog v-model="modal_asegurabilidad" persistent max-width="900px">
        <ModalAsegurabilidad
          :tipo_de_usuario="tipo_usuario_prop"
          :tratamientos="enfermedades_asegurabilidad"
          :tratamientos_seleccionados_prop="tratamientos_seleccionados_prop"
          @cerrar-modal="modal_asegurabilidad = false"
          @aceptar-modal="obtenerAsegurabilidad"
        ></ModalAsegurabilidad>
      </v-dialog>
    </template>
  </v-card>
</template>
<script>
import afiliacionService from "@/modules/afiliacion/services/afiliacionService";
import contratosService from "../services/contratosService";
import ModalDireccion from "@/modules/general/components/ModalDireccion.vue";
import ModalAsegurabilidad from "@/components/modales-familiar/ModalAsegurabilidad.vue";

export default {
  props: {
    id_titular: {
      type: Number,
      required: true,
    },
    id_grupo: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    //----------- TITULAR ------------
    headers_titular: [
      { text: "Documento", value: "numero_documento", align: "center" },
      { text: "1° Nombre", value: "primer_nombre", align: "center" },
      { text: "2° Nombre", value: "segundo_nombre", align: "center" },
      { text: "1° Apellido", value: "primer_apellido", align: "center" },
      { text: "2° Apellido", value: "segundo_apellido", align: "center" },
      { text: "Genero", value: "genero", align: "center" },
      { text: "Fecha Nacimiento", value: "fecha_nacimiento", align: "center" },
      { text: "Estado Civil", value: "estado_civil", align: "center" },
      { text: "Profesion", value: "profesion", align: "center" },
      { text: "Estrato", value: "estrato" },
      { text: "Telefono", value: "telefono", align: "center" },
      { text: "Correo", value: "correo", align: "center" },
      { text: "Direccion", value: "direccion_completa", align: "center" },
      // { text: "Usuario Red Medica", value: "usuario_red_medica", align: "center" },
      // { text: "Regimen", value: "regimen", align: "center" },
      // { text: "Eps", value: "eps", align: "center" },

      { text: "Acciones", value: "acciones" },
    ],
    items_titular: [],

    //ITEMS SELECTS
    profesiones: [],
    generos: [],
    estados_civiles: [],
    items_eps: [],
    regimenes: [],
    parentescos: [],

    //---------- BENEFICIARIOS -------------
    headers_beneficiarios: [
      { text: "Documento", value: "numero_documento", align: "center" },
      { text: "1° Nombre", value: "primer_nombre", align: "center" },
      { text: "2° Nombre", value: "segundo_nombre", align: "center" },
      { text: "1° Apellido", value: "primer_apellido", align: "center" },
      { text: "2° Apellido", value: "segundo_apellido", align: "center" },
      { text: "Genero", value: "genero", align: "center" },
      { text: "Fecha Nacimiento", value: "fecha_nacimiento", align: "center" },
      { text: "Estrato", value: "estrato", align: "center" },
      { text: "Eps", value: "eps", align: "center" },
      { text: "Regimen", value: "regimen", align: "center" },
      { text: "Parentesco", value: "parentesco", align: "center" },
      { text: "Correo", value: "correo", align: "center" },
      { text: "Telefono", value: "telefono", align: "center" },
      { text: "Celular", value: "celular", align: "center" },
      { text: "Direccion", value: "direccion_completa", align: "center" },
      {
        text: "Asegurabilidad",
        value: "asegurabilidad_texto",
        align: "center",
      },
      { text: "Acciones", value: "acciones" },
    ],
    items_beneficiarios: [],

    beneficiario: {
      id_grupo: null,
      id_titular: null,
      id: null,
      tipo_documento: 1,
      numero_documento: null,
      celular: null,
      telefono: null,
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      genero: null,
      fecha_nacimiento: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      estado_civil: null,
      profesion: null,
      estrato: null,
      direccion: null,
      direccion_completa: "",
      correo: "",
      regimen: null,
      parentesco: null,
      eps: "",
      asegurabilidad: [],
      asegurabilidad_texto: [],
      asegurabilidad_observaciones: "",
      anexos: [],
      editar: false,
      picker_fecha_nacimiento: false,
      respaldo: {},
      guardando_modificaciones: false,
    },

    //Modal asegurabilidad
    enfermedades_asegurabilidad: [],
    tratamientos_seleccionados_prop: [],
    modal_asegurabilidad: false,
    tipo_usuario_prop: "titular",
    id_usuario_asegurabilidad: null,

    //MODAL DIRECCION
    modal_direccion: false,
    json_direccion: {},
    es_direccion_titular: false,
    id_usuario: null,

    cargando_datos: false,
  }),
  components: {
    ModalDireccion,
    ModalAsegurabilidad,
  },
  async created() {
    this.cargando_datos = true;
    let enfermedades_asegurabilidad =
      await afiliacionService.getEnfermedadesAsegurabilidad();

    this.enfermedades_asegurabilidad = enfermedades_asegurabilidad.data;

    let profesiones = await afiliacionService.getProfesiones();

    this.profesiones = profesiones.data;

    let generos = await afiliacionService.getGeneros();

    this.generos = generos.data;

    let estados_civiles = await afiliacionService.getEstadosCiviles();

    this.estados_civiles = estados_civiles.data;

    let eps = await afiliacionService.getEps();

    this.items_eps = eps.data;

    let regimenes = await afiliacionService.getRegimenes();

    this.regimenes = regimenes.data;

    let parentescos = await afiliacionService.getParentescos();

    this.parentescos = parentescos.data;

    await contratosService
      .datosTitularBeneficiarios(this.id_titular, this.id_grupo)
      .then((response) => {
        if (response.data.datos_titular.length > 0) {
          response.data.datos_titular.forEach((titular) => {
            titular.profesion = parseInt(titular.profesion);
            Object.assign(titular, {
              editar: false,
              picker_fecha_nacimiento: false,
              respaldo: {},
              guardando_modificaciones: false,
            });
          });
        }

        if (response.data.beneficiarios.length > 0) {
          response.data.beneficiarios.forEach((beneficiario) => {
            this.beneficiario.id = beneficiario.ID_Ben;
            this.beneficiario.id_grupo = beneficiario.ID_Grupo;
            this.beneficiario.id_titular = beneficiario.ID_Titular;
            this.beneficiario.tipo_documento = beneficiario.TipoDoc;
            this.beneficiario.numero_documento = beneficiario.bendoc;
            this.beneficiario.celular = beneficiario.bencel;
            this.beneficiario.telefono = beneficiario.bentel;
            this.beneficiario.primer_nombre = beneficiario.bennm1;
            this.beneficiario.segundo_nombre = beneficiario.bennm2;
            this.beneficiario.primer_apellido = beneficiario.benap1;
            this.beneficiario.segundo_apellido = beneficiario.benap2;
            this.beneficiario.genero = beneficiario.id_sexo;
            this.beneficiario.fecha_nacimiento = beneficiario.bennac;
            this.beneficiario.estrato = parseInt(beneficiario.bentip);
            this.beneficiario.direccion = beneficiario.direccion;
            this.beneficiario.direccion_completa = beneficiario.bendir;
            this.beneficiario.correo = beneficiario.mail;
            this.beneficiario.regimen = beneficiario.regimen_eps;
            this.beneficiario.parentesco = beneficiario.Parentesco;
            this.beneficiario.eps = beneficiario.beneps;
            this.beneficiario.asegurabilidad =
              beneficiario.enfermedades_asegurabilidad;
            this.beneficiario.asegurabilidad_texto =
              beneficiario.enfermedades_asegurabilidad_texto;

            this.items_beneficiarios.push(this.beneficiario);
          });
        }

        this.items_titular = response.data.datos_titular;
      });

    this.cargando_datos = false;
  },

  methods: {
    editarTitular(item) {
      this.items_titular[0].respaldo = JSON.parse(JSON.stringify(item));
      item.editar = true;
    },
    cancelarEditarTitular(item) {
      item.editar = false;
      let saved = JSON.parse(JSON.stringify(this.items_titular[0].respaldo));
      this.items_titular = [];
      this.items_titular.push(saved);
    },
    editarBeneficiario(item) {
      item.respaldo = JSON.parse(JSON.stringify(item));
      item.editar = true;
    },
    cancelarEditarBeneficiario(item) {
      item.editar = false;
      let saved = JSON.parse(JSON.stringify(item.respaldo));
      let lista_actualizada = [];

      this.items_beneficiarios.forEach((element) => {
        if (element.id == item.id) {
          lista_actualizada.push(saved);
        } else {
          lista_actualizada.push(element);
        }
      });

      this.items_beneficiarios = lista_actualizada;
    },
    async guardarModificacionesTitular(titular) {
      if (this.$refs.formularioTitular.validate()) {
        titular.guardando_modificaciones = true;
        await contratosService
          .guardarModificacionTitular({ titular: titular })
          .then((response) => {});
        titular.editar = false;
        titular.guardando_modificaciones = false;
      }
    },
    async guardarModificacionesBeneficiario(beneficiario) {
      if (this.$refs.formularioBen.validate()) {
        beneficiario.guardando_modificaciones = true;
        await contratosService
          .guardarModificacionBeneficiario({ beneficiario: beneficiario })
          .then((response) => {
            console.log(response.data);
          });
        beneficiario.editar = false;
        beneficiario.guardando_modificaciones = false;
      }
    },
    abrirModalAsegurabilidad(tipo_usuario, item) {
      this.tipo_usuario_prop = tipo_usuario;
      this.id_usuario_asegurabilidad = item.id;
      this.tratamientos_seleccionados_prop = item.asegurabilidad;
      this.modal_asegurabilidad = true;
    },
    /**
     * Funcion que guarda todos los datos de las enfermedades de asegurabilidad seleccionadas por el usuario
     * @param {Array} tratamientos los id de los tratamientos selecionados
     * @param {string} observaciones las observaciones de los tratamientos seleccionados
     * @param {string} tipoUsuario usuario al que se le van a guardar los datos (titular o beneficiario)
     * @param {Array} tratamientos_texto los titulos de los tratamientos selecionados ya que son los que se van a mostrar en el input
     */
    obtenerAsegurabilidad(
      tratamientos,
      observaciones,
      tipoUsuario,
      tratamientos_texto
    ) {
      if (tipoUsuario == "titular") {
        this.titular.asegurabilidad = tratamientos;
        this.titular.asegurabilidad_texto = tratamientos_texto;
        this.titular.asegurabilidad_observaciones = observaciones;
        this.modal_asegurabilidad_titular = false;
      } else {
        if (this.items_beneficiarios.length > 0) {
          this.items_beneficiarios.forEach((beneficiario) => {
            if (beneficiario.id == this.id_usuario_asegurabilidad) {
              beneficiario.asegurabilidad = tratamientos;
              beneficiario.asegurabilidad_texto = tratamientos_texto;
              beneficiario.asegurabilidad_observaciones = observaciones;
            }
          });
        }
      }
      this.modal_asegurabilidad = false;
    },
    guardarDireccion(direccion) {
      if (this.es_direccion_titular) {
        this.items_titular[0].direccion = JSON.parse(JSON.stringify(direccion));

        this.items_titular[0].direccion_completa = JSON.parse(
          JSON.stringify(direccion.direccion_completa)
        );
        let saved = JSON.parse(JSON.stringify(this.items_titular[0]));
        this.items_titular = [];
        this.items_titular.push(saved);
      } else {
        if (this.id_usuario != null) {
          let lista_actualizada = [];

          this.items_beneficiarios.forEach((element) => {
            if (element.id == this.id_usuario) {
              element.direccion = JSON.parse(JSON.stringify(direccion));
              element.direccion_completa = JSON.parse(
                JSON.stringify(direccion.direccion_completa)
              );
            }

            lista_actualizada.push(element);
          });

          this.items_beneficiarios = lista_actualizada;
        }
      }
    },
    abrirModalDireccion(es_titular, item) {
      this.es_direccion_titular = es_titular;
      this.id_usuario = item.id;
      this.json_direccion = item.direccion;
      this.modal_direccion = true;
    },
    validarGenero(id_genero) {
      let texto_genero = "No Registra";
      if (this.generos.length > 0) {
        this.generos.forEach((genero) => {
          if (genero.id === id_genero) {
            texto_genero = genero.genero;
          }
        });
      }
      return texto_genero;
    },
    validarEstadoCivil(id_estado_civil) {
      let texto_estado_civil = "No Registra";
      if (this.estados_civiles.length > 0) {
        this.estados_civiles.forEach((estado_civil) => {
          if (estado_civil.id === id_estado_civil) {
            texto_estado_civil = estado_civil.estado_civil;
          }
        });
      }
      return texto_estado_civil;
    },

    validarProfesion(id_profesion) {
      let texto_profesion = "No Registra";
      if (this.profesiones.length > 0) {
        this.profesiones.forEach((profesion) => {
          if (profesion.id === id_profesion) {
            texto_profesion = profesion.profesion;
          }
        });
      }
      return texto_profesion;
    },

    validarEps(id_eps) {
      let texto_eps = "No Registra";
      if (this.items_eps.length > 0) {
        this.items_eps.forEach((eps) => {
          if (eps.epscod === id_eps) {
            texto_eps = eps.epsnom;
          }
        });
      }
      return texto_eps;
    },

    validarRegimen(id_regimen) {
      let texto_regimen = "No Registra";
      if (this.regimenes.length > 0) {
        this.regimenes.forEach((regimen) => {
          if (regimen.id === id_regimen) {
            texto_regimen = regimen.regimen;
          }
        });
      }
      return texto_regimen;
    },

    validarParentesco(id_parentesco) {
      let texto_parentesco = "No Registra";
      if (this.parentescos.length > 0) {
        this.parentescos.forEach((parentesco) => {
          if (parentesco.ID_Paren === id_parentesco) {
            texto_parentesco = parentesco.parentesco;
          }
        });
      }

      return texto_parentesco;
    },
  },
};
</script>
<style scoped>
.input-general {
  width: 140px;
}
</style>
