import apiClient from "../../general/services/index.js";

const relative_path = "contratos";

export default {
  consultarContratos(params) {
    return apiClient.post(relative_path + "/consultar-contratos", params);
  },
  obtenerDatosVistaConsultarContratos() {
    return apiClient.get(
      relative_path + "/obtener-datos-vista-consultar-contratos"
    );
  },
  datosTitularBeneficiarios(id_titular, id_grupo) {
    return apiClient.get(
      relative_path +
        "/datos-titular-beneficiarios" +
        "/" +
        id_titular +
        "/" +
        id_grupo
    );
  },
  guardarEstadoContrato(data_contrato) {
    return apiClient.post(
      relative_path + "/guardar-estado-contrato",data_contrato
    );
  },
  motivosDesaprobacion(){
    return apiClient.get(
      relative_path + "/motivos-desaprobacion"
    );
  },
  agregarMotivoDesaprobacion(nuevo_motivo_desaprobacion) {
    return apiClient.post(
      relative_path + "/guardar-nuevo-motivo-desaprobacion",nuevo_motivo_desaprobacion
    );
  },
  guardarModificacionTitular(titular) {
    return apiClient.post(
      relative_path + "/guardar-modificacion-titular",
      titular
    );
  },
  guardarModificacionBeneficiario(beneficiario) {
    return apiClient.post(
      relative_path + "/guardar-modificacion-beneficiario",
      beneficiario
    );
  },
  consultarAnexosContrato(id_grupo) {
    return apiClient.get(
      relative_path + "/consultar-anexos-contrato/" + id_grupo
    );
  },
  consultarBeneficiariosContrato(id_grupo) {
    return apiClient.get(
      relative_path + "/consultar-beneficiarios-contrato/" + id_grupo
    );
  },
  previsualizarAnexo(ruta_archivo) {
    return apiClient.post(relative_path + "/previsualizar-anexo", {
      ruta_archivo: ruta_archivo,
    });
  },
  descargarAnexo(ruta_archivo) {
    return apiClient.post(
      relative_path + "/descargar-anexo",
      { ruta_archivo: ruta_archivo },
      { responseType: "arraybuffer" }
    );
  },
  guardarAnexosTitular(data) {
    return apiClient.post(relative_path + "/guardar-anexos-titular", data);
  },
  guardarAnexosBeneficiario(data) {
    return apiClient.post(relative_path + "/guardar-anexos-beneficiario", data);
  },
  borrarAnexo(id_anexo) {
    return apiClient.patch(relative_path + "/eliminar-anexo/" + id_anexo);
  },
};
