<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="grey darken-3" dark height="40">
        <v-spacer></v-spacer>
        <v-toolbar-title>Consultar Contratos</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row class="mb-n8 mt-n8">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-switch label="+ Filtros" v-model="mas_filtros"> </v-switch>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="1" class="d-flex align-center justify-center" v-if="mas_filtros">
            <v-switch v-model="todos_contratos" label="Todos los Contratos?">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="3" md="2" lg="2" class="mb-n8">
            <label for="">Fecha inicial</label>
            <v-menu v-model="menu_fecha_inicial" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :disabled="todos_contratos" v-model="fecha_inicial"
                  @click:append="menu_fecha_inicial = true" append-icon="mdi-calendar" readonly outlined dense
                  v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker locale="es" v-model="fecha_inicial" @input="menu_fecha_inicial = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3" lg="2" class="mb-n8">
            <label for="">Fecha final</label>
            <v-menu v-model="menu_fecha_final" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="fecha_final" :disabled="todos_contratos" dense outlined append-icon="mdi-calendar"
                  @click:append="menu_fecha_final = true" readonly v-bind="attrs" v-on="on"
                  :rules="[(v) => !!v || 'Campo requerido']"></v-text-field>
              </template>
              <v-date-picker locale="es" v-model="fecha_final" :min="fecha_inicial"
                @input="menu_fecha_final = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="2" md="2" lg="2" class="mb-n8">
            <label>Estado Revisión</label>
            <v-select :menu-props="{ bottom: true, offsetY: true }" :items="estados_revision" item-text="estado_revision"
              item-value="id" v-model="estado_revision" outlined dense></v-select>
          </v-col>
          <v-col cols="12" sm="2" md="2" lg="2" class="mb-n8">
            <label>Estado Aprobación</label>
            <v-select :menu-props="{ bottom: true, offsetY: true }" :items="estados_aprobacion"
              v-model="estado_aprobacion" outlined dense></v-select>
          </v-col>
          <v-col cols="12" sm="2" md="2" lg="2" class="mb-n8">
            <label>Motivo Desaprobación</label>
            <v-select :menu-props="{ bottom: true, offsetY: true }" :disabled="estado_aprobacion == 1"
              :items="motivos_desaprobacion" item-text="motivo_desaprobacion" item-value="id"
              v-model="motivo_desaprobacion" outlined dense></v-select>
          </v-col>
          <v-col cols="12" sm="2" md="2" lg="2" v-if="mas_filtros">
            <label>N° Documento</label>
            <v-text-field type="number" v-model="numero_documento" outlined dense></v-text-field>
          </v-col>
          <v-col cols="1" class="d-flex align-center justify-center mt-6">
            <v-btn color="primary" @click="buscarContratos">
              Buscar
              <v-icon class="ml-1"> mdi-magnify </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :search="search" :items="datatable_items" :loading="buscando_contratos"
              no-data-text="No hay Resultados">
              <template v-slot:top>
                <v-row class="justify-end">
                  <v-col cols="4">
                    <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Buscar"
                      class="mx-4"></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.estado_revision_texto="{ item }">
                <v-chip dark :color="item.color_estado_revision">{{
                  item.estado_revision_texto
                }}</v-chip>
              </template>

              <template v-slot:item.estado_aprobacion="{ item }">
                <v-chip dark :color="item.estado_aprobacion != null
                  ? item.estado_aprobacion
                    ? '#00EA2E'
                    : '#FF0000'
                  : 'gray'
                  ">
                  {{
                    item.estado_aprobacion != null
                    ? item.estado_aprobacion
                      ? "Aprobado"
                      : "Desaprobado(" + item.motivo_desaprobacion_texto + ")"
                    : "No registrado"
                  }}
                </v-chip>
              </template>

              <template v-slot:item.Tarifa="{ item }">
                ${{ item.Tarifa | moneda }}
              </template>

              <template v-slot:item.descuento="{ item }">
                ${{ item.descuento | moneda }}
              </template>

              <template v-slot:item.config_descuento_individual="{ item }">
                {{ item.config_descuento_individual ? "Si" : "No" }}
              </template>

              <template v-slot:item.numero_afiliados="{ item }">
                <a @click="abrirModalBeneficiariosContrato(item.ID_Grupo)">
                  {{ item.numero_afiliados }}</a>
              </template>

              <template v-slot:item.total_pagado="{ item }">
                ${{ item.total_pagado | moneda }}
              </template>

              <template v-slot:item.opciones="props">
                <div class="text-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list>
                      <!-- Se itera sobre las opciones disponibles -->
                      <div v-for="(item, index) in items_opciones" :key="index" class="mx-4">
                        <!-- <div v-if="item.title == 'Estado' && props.item.estado_aprobacion == 1">
                        </div> -->
                        <v-list-item  @click="opcionesMenu(props.item, item.value)">
                          <v-list-item-title class="list-item">
                            <span>{{ item.title }}</span>
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- MODAL ANEXOS CONTRATO -->
      <v-dialog v-model="modal_anexos_contrato" width="800" persistent>
        <ModalAnexosContrato v-if="modal_anexos_contrato" @cerrar-modal="modal_anexos_contrato = false"
          :nombre_titular="nombre_titular_prop" :id_grupo="id_grupo_prop" :id_titular="id_titular_prop"
          :documento_titular="documento_titular_prop" />
      </v-dialog>

      <!-- MODAL ESTADO CONTRATO -->
      <v-dialog v-model="modal_estado_contrato" width="500" persistent>
        <ModalEstadoContrato v-if="modal_estado_contrato" @cerrar-modal="modal_estado_contrato = false"
          :estados_revision="estados_revision_prop" :estado_revision_prop="estado_revision_prop"
          :estado_aprobacion_prop="estado_aprobacion_prop" :motivo_desaprobacion_prop="motivo_desaprobacion_prop"
          :id_grupo="id_grupo_prop" @guardar-estado-contrato="guardarEstadoContrato" />
      </v-dialog>

      <!-- MODAL BENEFICIARIOS CONTRATO -->
      <v-dialog v-model="modal_beneficiarios_contrato" width="1200" persistent>
        <ModalBeneficiariosContrato v-if="modal_beneficiarios_contrato"
          @cerrar-modal="modal_beneficiarios_contrato = false" :id_grupo="id_grupo_prop" />
      </v-dialog>

      <!-- MODAL MODIFICAR TITULAR Y BENEFICIARIOS -->
      <v-dialog v-model="modal_modificar_tit_ben" fullscreen persistent>
        <ModalModificarTitBen v-if="modal_modificar_tit_ben" :id_grupo="id_grupo_prop" :id_titular="id_titular_prop"
          @cerrar-modal="modal_modificar_tit_ben = false" />
      </v-dialog>
    </v-card>
  </v-container>
</template>
<script>

import ModalModificarTitBen from "./components/ModalModificarTitBen.vue";
import ModalEstadoContrato from "./components/ModalEstadoContrato.vue";
import ModalBeneficiariosContrato from "./components/ModalBeneficiariosContrato.vue";
import ModalAnexosContrato from "./components/ModalAnexosContrato.vue";
import contratosService from "./services/contratosService";

export default {
  data: () => ({
    headers: [
      {
        text: "Nombre Titular",
        value: "titnom",
        filterable: true,
        align: "center",
      },
      { text: "Documento", value: "grunit", filterable: true, align: "center" },
      { text: "Celular", value: "grucel", filterable: true, align: "center" },
      { text: "Correo", value: "mail", filterable: true, align: "center" },
      {
        text: "Fecha Tarifa",
        value: "fecha_tarifa",
        filterable: false,
        align: "center",
      },
      {
        text: "Tarifa Individual",
        value: "Tarifa",
        filterable: false,
        align: "center",
      },
      {
        text: "N° Afiliados",
        value: "numero_afiliados",
        filterable: false,
        align: "center",
      },
      {
        text: "Descuento",
        value: "descuento",
        filterable: false,
        align: "center",
      },
      {
        text: "Descuento Individual",
        value: "config_descuento_individual",
        filterable: false,
        align: "center",
      },
      {
        text: "Tarifa Total",
        value: "total_pagado",
        filterable: false,
        align: "center",
      },
      {
        text: "Estado revisión",
        value: "estado_revision_texto",
        filterable: false,
      },
      {
        text: "Estado aprobación",
        value: "estado_aprobacion",
        filterable: false,
      },
      { text: "Opciones", value: "opciones", filterable: false },
    ],
    datatable_items: [],

    mas_filtros: false,

    todos_contratos: false,

    fecha_inicial: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu_fecha_inicial: false,

    fecha_final: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    menu_fecha_final: false,

    estados_revision: [],
    estado_revision: 3,

    estados_aprobacion: [
      { text: "Todos", value: -1 },
      { text: "Aprobado", value: 1 },
      { text: "Desaprobado", value: 0 },
    ],
    estado_aprobacion: -1,

    motivos_desaprobacion: [],
    motivo_desaprobacion: -1,

    numero_documento: null,

    search: "",

    items_opciones: [
      {
        title: "Anexos",
        value: 1,
      },
      {
        title: "Modificar",
        value: 2,
      },
      {
        title: "Estado",
        value: 3,
      },
      {
        title: "Enviar Contrato",
        value: 4,
      },
    ],

    buscando_contratos: false,

    //-------- MODALES --------------

    modal_anexos_contrato: false,
    nombre_titular_prop: null,
    id_grupo_prop: null,
    id_titular_prop: null,
    documento_titular_prop: null,

    //Modal beneficiarios contrato
    modal_beneficiarios_contrato: false,

    //Modal modificar titular y beneficiarios
    modal_modificar_tit_ben: false,

    //Modal estado contrato
    modal_estado_contrato: false,
    estados_revision_prop: [],
    estado_aprobacion_prop: null,
    estado_revision_prop: null,
    motivo_desaprobacion_prop: null,
  }),
  components: {
    ModalAnexosContrato,
    ModalBeneficiariosContrato,
    ModalBeneficiariosContrato,
    ModalModificarTitBen,
    ModalModificarTitBen,
    ModalEstadoContrato,
    ModalEstadoContrato
  },
  async created() {
    let d = new Date();
    d.setDate(1);
    (this.fecha_inicial = new Date(d - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10)),
      //Se activa el icono de carga de la pantalla
      (this.$store.state.loading = true);

    await contratosService
      .obtenerDatosVistaConsultarContratos()
      .then((response) => {
        this.estados_revision = response.data.estados_revision;
        this.estados_revision_prop = JSON.parse(JSON.stringify(response.data.estados_revision));
        this.estados_revision.unshift({ id: -1, estado_revision: "Todos" });
        this.motivos_desaprobacion = response.data.motivos_desaprobacion;
        this.motivos_desaprobacion.unshift({
          id: -1,
          motivo_desaprobacion: "Todos",
        });
      });

    await this.buscarContratos();

    this.$store.state.loading = false;
  },
  methods: {
    async buscarContratos() {
      this.buscando_contratos = true;
      this.datatable_items = [];
      await contratosService
        .consultarContratos({
          todos_contratos: this.todos_contratos,
          fecha_inicial: this.fecha_inicial,
          fecha_final: this.fecha_final,
          estado_revision: this.estado_revision,
          estado_aprobacion: this.estado_aprobacion,
          motivo_desaprobacion: this.motivo_desaprobacion,
          numero_documento: this.numero_documento,
        })
        .then((response) => {
          this.datatable_items = response.data;
        });
      this.buscando_contratos = false;
    },

    abrirModalBeneficiariosContrato(id_grupo) {
      this.id_grupo_prop = id_grupo;
      this.modal_beneficiarios_contrato = true;
    },

    /**
     * Funcion que filtra la opcion seleccionada por el usuario
     * @param {object} item recibo sobre el cual se selecciono la opcion
     * @param {integer} opcion opcion seleccionada por el cliente
     * @return {void}
     */
    async opcionesMenu(item, opcion) {
      this.id_grupo_prop = item.ID_Grupo;
      switch (opcion) {
        case 1:
          this.nombre_titular_prop = item.grunom;
          this.id_titular_prop = item.ID_Titular;
          this.documento_titular_prop = item.grunit;
          this.modal_anexos_contrato = true;
          break;
        case 2:
          this.id_titular_prop = item.ID_Titular;
          this.modal_modificar_tit_ben = true;
          break;
        case 3:
          this.estado_revision_prop = item.estado_revision;
          this.estado_aprobacion_prop = item.estado_aprobacion;
          this.motivo_desaprobacion_prop = item.motivo_desaprobacion;
          this.modal_estado_contrato = true;
          break;
        default:

          return;
      }
    },


    guardarEstadoContrato(datos_estado) {
      let item = this.datatable_items.filter((element) => element.ID_Grupo === datos_estado.id_grupo);
      if (item.length > 0) {
        item[0].estado_revision = datos_estado.estado_revision;
        item[0].estado_revision_texto = datos_estado.estado_revision_texto;
        item[0].color_estado_revision = datos_estado.color_estado_revision;
        item[0].estado_aprobacion = datos_estado.estado_aprobacion;
        item[0].motivo_desaprobacion = datos_estado.motivo_desaprobacion;
        item[0].motivo_desaprobacion_texto = datos_estado.motivo_desaprobacion_texto;
      }
    }
  },
};
</script>
<style></style>
