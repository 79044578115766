<template>
       <v-container class="background">
                <v-row>
                    
                </v-row>

               <v-row>
                 <v-col cols="12" sm="" md="" lg="3" style="text-align:center">
                    <v-card elevation="0"  @click="irAContratos()">
                        <v-card-title class="justify-center">Contratos</v-card-title>
                        <v-card-text>
                           <v-btn text >
                             <v-icon>
                               mdi-file-sign
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                 </v-col>
                 <v-col cols="12" sm="" md="" lg="3" style="text-align:center">
                    <v-card elevation="0" >
                        <v-card-title class="justify-center">Informes</v-card-title>
                        <v-card-text>
                           <v-btn text >
                             <v-icon>
                                mdi-chart-timeline
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="" md="" lg="3" style="text-align:center">
                    <v-card elevation="0" >
                        <v-card-title class="justify-center">Comisiones</v-card-title>
                        <v-card-text>
                           <v-btn text>
                             <v-icon>
                               mdi-hand-coin
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                 </v-col>
                 <v-col cols="12" sm="" md="" lg="3" style="text-align:center">
                    <v-card elevation="0"  >
                        <v-card-title class="justify-center">Empresas</v-card-title>
                        <v-card-text>
                           <v-btn text>
                             <v-icon>
                               mdi-domain
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                 </v-col>
               </v-row>


    </v-container>
</template>

<script>


  export default {
    name: 'Home',
    data: () => ({
      contrato: false,

      breadcrumbs: [
        {
          text: 'Inicio',
          disabled: false,
          href: '',
        },
        {
          text: 'Link 1',
          disabled: false,
          href: 'breadcrumbs_link_2',
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'breadcrumbs_link_2',
        },
      ],

        subItemsContrato: [
                {
                titulo: 'Familiar', icono: 'mdi-human-male-female-child', action: 'Familiar'
                },
                {
                titulo: 'Colectivo', icono: 'mdi-crowd', action: 'Colectivo'
                },
                {
                titulo: 'Area Protegida', icono: 'mdi-alert', action: 'AreaProtegida'
                },
            ],


      anexoContratos: [
        ['Familiares Anexo', 'mdi-human-male-female-child'],
        ['Colectivo Anexo', 'mdi-crowd'],
      ],

      gestionarContratos:[
        { titulo: 'Contratos Guardados', icono: 'mdi-file-plus',  action: 'contratos-guardados'},
        { titulo: 'Estados Contratos', icono:'medi-badge-account', action: 'estados-contratos'}
      ],

      itemsContrato: [
       { 
         titulo: 'Agregar Nuevo Contrato',
         icono: 'mdi-file-plus'
       },
       {
         titulo: 'Anexo Contrato',
         icono: 'mdi-finance'
       },
       {
         titulo: 'Gestionar Contrato',
         icono: 'mdi-file-cog',
       },
         {
         titulo: 'Simular Tarifa',
         icono: 'mdi-finance'
       },
      ],
       selectedItem: 1,
      items: [
        { text: 'Simular Tarifa', icon: 'mdi-clock', action: 'simular-tarifa' },
     
      ],
    }),
    components: {

    },
    methods: {
   
   irAContratos(){
       this.$router.push({ path: 'contratos' })
   },
 

    }
  }
</script>

<style scoped>
.background{
    background: rgb(34, 78, 201) !important;
}
</style>