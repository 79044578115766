var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dark":"","height":"40","color":"indigo darken-1"}},[_c('v-spacer'),_c('v-toolbar-title',{staticClass:"mr-n16"},[_vm._v("Modificar")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.$emit('cerrar-modal')}}},[_c('v-icon',[_vm._v("mdi-alpha-x")])],1)],1),(!_vm.cargando_datos)?_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"mt-2",staticStyle:{"font-size":"42px"}},[_vm._v("Titular")])]),_c('v-form',{ref:"formularioTitular"},[_c('v-data-table',{staticClass:"ma-0 pa-0",attrs:{"items":_vm.items_titular,"headers":_vm.headers_titular,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.primer_nombre",fn:function(ref){
var item = ref.item;
return [(item.editar)?_c('v-col',{staticStyle:{"width":"200px"}},[(item.editar)?_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","rules":[function (v) { return !!v || 'Campo Requerido'; }]},model:{value:(item.primer_nombre),callback:function ($$v) {_vm.$set(item, "primer_nombre", $$v)},expression:"item.primer_nombre"}}):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(item.primer_nombre)+" ")])]}},{key:"item.segundo_nombre",fn:function(ref){
var item = ref.item;
return [(item.editar)?_c('v-col',{staticStyle:{"width":"200px"}},[(item.editar)?_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":""},model:{value:(item.segundo_nombre),callback:function ($$v) {_vm.$set(item, "segundo_nombre", $$v)},expression:"item.segundo_nombre"}}):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(item.segundo_nombre == null ? "No Registra" : item.segundo_nombre)+" ")])]}},{key:"item.primer_apellido",fn:function(ref){
var item = ref.item;
return [(item.editar)?_c('v-col',{staticStyle:{"width":"200px"}},[(item.editar)?_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","rules":[function (v) { return !!v || 'Campo Requerido'; }]},model:{value:(item.primer_apellido),callback:function ($$v) {_vm.$set(item, "primer_apellido", $$v)},expression:"item.primer_apellido"}}):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(item.primer_apellido)+" ")])]}},{key:"item.segundo_apellido",fn:function(ref){
var item = ref.item;
return [(item.editar)?_c('v-col',{staticStyle:{"width":"200px"}},[(item.editar)?_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","rules":[function (v) { return !!v || 'Campo Requerido'; }]},model:{value:(item.segundo_apellido),callback:function ($$v) {_vm.$set(item, "segundo_apellido", $$v)},expression:"item.segundo_apellido"}}):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(item.segundo_apellido)+" ")])]}},{key:"item.profesion",fn:function(ref){
var item = ref.item;
return [(item.editar)?_c('div',{staticClass:"input-general"},[_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.profesiones,"item-text":"profesion","item-value":"id","menu-props":{ bottom: true, offsetY: true },"rules":[function (v) { return !!v || 'Campo Requerido'; }],"dense":""},model:{value:(item.profesion),callback:function ($$v) {_vm.$set(item, "profesion", $$v)},expression:"item.profesion"}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.validarProfesion(item.profesion))+" ")])]}},{key:"item.fecha_nacimiento",fn:function(ref){
var item = ref.item;
return [(item.editar)?_c('div',{staticStyle:{"width":"110px"}},[(item.editar)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"dense":"","outlined":"","transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"dense":"","append-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v || 'Campo Requerido'; }]},on:{"click:append":function($event){item.picker_fecha_nacimiento = true}},model:{value:(item.fecha_nacimiento),callback:function ($$v) {_vm.$set(item, "fecha_nacimiento", $$v)},expression:"item.fecha_nacimiento"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.picker_fecha_nacimiento),callback:function ($$v) {_vm.$set(item, "picker_fecha_nacimiento", $$v)},expression:"item.picker_fecha_nacimiento"}},[_c('v-date-picker',{attrs:{"min":"1923-01-01","locale":"es"},on:{"input":function($event){item.picker_fecha_nacimiento = false}},model:{value:(item.fecha_nacimiento),callback:function ($$v) {_vm.$set(item, "fecha_nacimiento", $$v)},expression:"item.fecha_nacimiento"}})],1):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(item.fecha_nacimiento)+" ")])]}},{key:"item.genero",fn:function(ref){
var item = ref.item;
return [(item.editar)?_c('v-col',{staticClass:"input-general"},[(item.editar)?_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.generos,"item-text":"genero","item-value":"id","menu-props":{ bottom: true, offsetY: true },"rules":[function (v) { return !!v || 'Campo Requerido'; }],"dense":""},model:{value:(item.genero),callback:function ($$v) {_vm.$set(item, "genero", $$v)},expression:"item.genero"}}):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(_vm.validarGenero(item.genero))+" ")])]}},{key:"item.estado_civil",fn:function(ref){
var item = ref.item;
return [(item.editar)?_c('div',{staticStyle:{"width":"120px"}},[_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.estados_civiles,"item-text":"estado_civil","item-value":"id","menu-props":{ bottom: true, offsetY: true },"rules":[function (v) { return !!v || 'Campo Requerido'; }],"dense":""},model:{value:(item.estado_civil),callback:function ($$v) {_vm.$set(item, "estado_civil", $$v)},expression:"item.estado_civil"}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.validarEstadoCivil(item.estado_civil))+" ")])]}},{key:"item.correo",fn:function(ref){
var item = ref.item;
return [(item.editar)?_c('v-col',{staticStyle:{"width":"200px"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","rules":[
                  function (v) { return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'E-mail debe ser valido'; } ]},model:{value:(item.correo),callback:function ($$v) {_vm.$set(item, "correo", $$v)},expression:"item.correo"}})],1):_c('div',[_vm._v(" "+_vm._s(item.correo)+" ")])]}},{key:"item.direccion_completa",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',[_c('a',{on:{"click":function($event){return _vm.abrirModalDireccion(true, item)}}},[_vm._v(" "+_vm._s(item.direccion.direccion_completa))])]):_c('div',[_vm._v(" "+_vm._s(item.direccion.direccion_completa)+" ")])]}},{key:"item.telefono",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',{staticStyle:{"width":"110px"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"type":"number","dense":""},model:{value:(item.telefono),callback:function ($$v) {_vm.$set(item, "telefono", $$v)},expression:"item.telefono"}})],1):_c('div',[_vm._v(" "+_vm._s(item.telefono == null ? "No registra" : item.telefono)+" ")])]}},{key:"item.estrato",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',[_c('v-text-field',{staticClass:"mt-2",attrs:{"type":"number","dense":""},model:{value:(item.estrato),callback:function ($$v) {_vm.$set(item, "estrato", $$v)},expression:"item.estrato"}})],1):_c('div',[_vm._v(" "+_vm._s(item.estrato)+" ")])]}},{key:"item.acciones",fn:function(ref){
                var item = ref.item;
return [_c('v-row',{staticClass:"justify-center"},[(!item.editar)?_c('v-btn',{staticClass:"ml-n2",attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.editarTitular(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(item.editar)?_c('v-btn',{attrs:{"disabled":item.guardando_modificaciones,"color":"red","small":"","icon":""},on:{"click":function($event){return _vm.cancelarEditarTitular(item)}}},[_c('v-icon',[_vm._v("mdi-cancel")])],1):_vm._e(),(item.editar)?_c('v-btn',{attrs:{"loading":item.guardando_modificaciones,"color":"success","small":"","icon":""},on:{"click":function($event){return _vm.guardarModificacionesTitular(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()],1)]}}],null,true)})],1)],1),_c('v-divider'),_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"mt-2",staticStyle:{"font-size":"42px"}},[_vm._v("Beneficiarios")])]),_c('v-form',{ref:"formularioBen"},[_c('v-data-table',{attrs:{"items":_vm.items_beneficiarios,"headers":_vm.headers_beneficiarios},scopedSlots:_vm._u([{key:"item.primer_nombre",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('v-col',{staticClass:"input-general"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","rules":[function (v) { return !!v || 'Campo Requerido'; }]},model:{value:(item.primer_nombre),callback:function ($$v) {_vm.$set(item, "primer_nombre", $$v)},expression:"item.primer_nombre"}})],1):_c('div',[_vm._v(" "+_vm._s(item.primer_nombre)+" ")])]}},{key:"item.segundo_nombre",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('v-col',{staticClass:"input-general"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":""},model:{value:(item.segundo_nombre),callback:function ($$v) {_vm.$set(item, "segundo_nombre", $$v)},expression:"item.segundo_nombre"}})],1):_c('div',[_vm._v(" "+_vm._s(item.segundo_nombre == null ? "No Registra" : item.segundo_nombre)+" ")])]}},{key:"item.primer_apellido",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('v-col',{staticClass:"input-general"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","rules":[function (v) { return !!v || 'Campo Requerido'; }]},model:{value:(item.primer_apellido),callback:function ($$v) {_vm.$set(item, "primer_apellido", $$v)},expression:"item.primer_apellido"}})],1):_c('div',[_vm._v(" "+_vm._s(item.primer_apellido)+" ")])]}},{key:"item.segundo_apellido",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('v-col',{staticClass:"input-general"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","rules":[function (v) { return !!v || 'Campo Requerido'; }]},model:{value:(item.segundo_apellido),callback:function ($$v) {_vm.$set(item, "segundo_apellido", $$v)},expression:"item.segundo_apellido"}})],1):_c('div',[_vm._v(" "+_vm._s(item.segundo_apellido)+" ")])]}},{key:"item.fecha_nacimiento",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',{staticStyle:{"width":"120px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"dense":"","outlined":"","transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"dense":"","append-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v || 'Campo Requerido'; }]},on:{"click:append":function($event){item.picker_fecha_nacimiento = true}},model:{value:(item.fecha_nacimiento),callback:function ($$v) {_vm.$set(item, "fecha_nacimiento", $$v)},expression:"item.fecha_nacimiento"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.picker_fecha_nacimiento),callback:function ($$v) {_vm.$set(item, "picker_fecha_nacimiento", $$v)},expression:"item.picker_fecha_nacimiento"}},[_c('v-date-picker',{attrs:{"min":"1923-01-01","locale":"es"},on:{"input":function($event){item.picker_fecha_nacimiento = false}},model:{value:(item.fecha_nacimiento),callback:function ($$v) {_vm.$set(item, "fecha_nacimiento", $$v)},expression:"item.fecha_nacimiento"}})],1)],1):_c('div',[_vm._v(" "+_vm._s(item.fecha_nacimiento)+" ")])]}},{key:"item.genero",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('v-col',{staticClass:"input-general"},[_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.generos,"item-text":"genero","item-value":"id","menu-props":{ bottom: true, offsetY: true },"rules":[function (v) { return !!v || 'Campo Requerido'; }],"dense":""},model:{value:(item.genero),callback:function ($$v) {_vm.$set(item, "genero", $$v)},expression:"item.genero"}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.validarGenero(item.genero))+" ")])]}},{key:"item.direccion_completa",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',[_c('a',{on:{"click":function($event){return _vm.abrirModalDireccion(false, item)}}},[_vm._v(" "+_vm._s(item.direccion.direccion_completa))])]):_c('div',[_vm._v(" "+_vm._s(item.direccion.direccion_completa)+" ")])]}},{key:"item.telefono",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',{staticStyle:{"width":"110px"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"type":"number","dense":""},model:{value:(item.telefono),callback:function ($$v) {_vm.$set(item, "telefono", $$v)},expression:"item.telefono"}})],1):_c('div',[_vm._v(" "+_vm._s(item.telefono == null ? "No registra" : item.telefono)+" ")])]}},{key:"item.celular",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',{staticStyle:{"width":"110px"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"type":"number","dense":""},model:{value:(item.celular),callback:function ($$v) {_vm.$set(item, "celular", $$v)},expression:"item.celular"}})],1):_c('div',[_vm._v(" "+_vm._s(item.celular)+" ")])]}},{key:"item.estrato",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',[_c('v-text-field',{staticClass:"mt-2",attrs:{"type":"number","dense":""},model:{value:(item.estrato),callback:function ($$v) {_vm.$set(item, "estrato", $$v)},expression:"item.estrato"}})],1):_c('div',[_vm._v(" "+_vm._s(item.estrato)+" ")])]}},{key:"item.eps",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',{staticClass:"input-general"},[_c('v-autocomplete',{attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.items_eps,"item-text":"epsnom","item-value":"epscod","rules":[function (v) { return !!v || 'Campo Requerido'; }],"dense":""},model:{value:(item.eps),callback:function ($$v) {_vm.$set(item, "eps", $$v)},expression:"item.eps"}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.validarEps(item.eps))+" ")])]}},{key:"item.correo",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('v-col',{staticStyle:{"width":"200px"}},[_c('v-text-field',{attrs:{"dense":"","rules":[
                  function (v) { return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'E-mail debe ser valido'; } ]},model:{value:(item.correo),callback:function ($$v) {_vm.$set(item, "correo", $$v)},expression:"item.correo"}})],1):_c('div',[_vm._v(" "+_vm._s(item.correo)+" ")])]}},{key:"item.regimen",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',{staticClass:"input-general"},[_c('v-select',{attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.regimenes,"item-text":"regimen","item-value":"id","rules":[function (v) { return !!v || 'Campo requerido'; }],"dense":""},model:{value:(item.regimen),callback:function ($$v) {_vm.$set(item, "regimen", $$v)},expression:"item.regimen"}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.validarRegimen(item.regimen))+" ")])]}},{key:"item.parentesco",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',[_c('v-select',{attrs:{"items":_vm.parentescos,"item-text":"parentesco","item-value":"ID_Paren","menu-props":{ bottom: true, offsetY: true },"dense":"","rules":[function (v) { return !!v || 'Campo requerido'; }]},model:{value:(item.parentesco),callback:function ($$v) {_vm.$set(item, "parentesco", $$v)},expression:"item.parentesco"}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.validarParentesco(item.parentesco))+" ")])]}},{key:"item.asegurabilidad_texto",fn:function(ref){
                var item = ref.item;
return [(item.editar)?_c('div',{on:{"click":function($event){return _vm.abrirModalAsegurabilidad('beneficiario', item)}}},[_c('a',_vm._l((item.asegurabilidad_texto),function(enfermedad,index){return _c('div',{key:index},[(index == item.asegurabilidad_texto.length - 1)?_c('span',[_vm._v(" "+_vm._s(enfermedad)+" ")]):_c('span',[_vm._v(" "+_vm._s(enfermedad + ", ")+" ")])])}),0)]):_c('div',_vm._l((item.asegurabilidad_texto),function(enfermedad,index){return _c('div',{key:index},[(index == item.asegurabilidad_texto.length - 1)?_c('span',[_vm._v(" "+_vm._s(enfermedad)+" ")]):_c('span',[_vm._v(" "+_vm._s(enfermedad + ", ")+" ")])])}),0)]}},{key:"item.acciones",fn:function(ref){
                var item = ref.item;
return [_c('v-row',{staticClass:"justify-center"},[(!item.editar)?_c('v-btn',{staticClass:"ml-n2",attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.editarBeneficiario(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(item.editar)?_c('v-btn',{attrs:{"disabled":item.guardando_modificaciones,"color":"red","small":"","icon":""},on:{"click":function($event){return _vm.cancelarEditarBeneficiario(item)}}},[_c('v-icon',[_vm._v("mdi-cancel")])],1):_vm._e(),(item.editar)?_c('v-btn',{attrs:{"loading":item.guardando_modificaciones,"color":"success","small":"","icon":""},on:{"click":function($event){return _vm.guardarModificacionesBeneficiario(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()],1)]}}],null,true)})],1)],1)],1):_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","margin-top":"250px"},attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"size":250,"color":"primary","indeterminate":""}})],1),_c('v-dialog',{attrs:{"width":"900"},model:{value:(_vm.modal_direccion),callback:function ($$v) {_vm.modal_direccion=$$v},expression:"modal_direccion"}},[(_vm.modal_direccion)?_c('ModalDireccion',{attrs:{"json_direccion":_vm.json_direccion},on:{"guardar_direccion":_vm.guardarDireccion,"cerrar":function($event){_vm.modal_direccion = false}}}):_vm._e()],1),(_vm.modal_asegurabilidad)?[_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.modal_asegurabilidad),callback:function ($$v) {_vm.modal_asegurabilidad=$$v},expression:"modal_asegurabilidad"}},[_c('ModalAsegurabilidad',{attrs:{"tipo_de_usuario":_vm.tipo_usuario_prop,"tratamientos":_vm.enfermedades_asegurabilidad,"tratamientos_seleccionados_prop":_vm.tratamientos_seleccionados_prop},on:{"cerrar-modal":function($event){_vm.modal_asegurabilidad = false},"aceptar-modal":_vm.obtenerAsegurabilidad}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }