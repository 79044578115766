<template>
  <v-card>
    <v-toolbar dark height="40" color="indigo darken-1">
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-n16">Anexos</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="red" small @click="$emit('cerrar-modal')"
        ><v-icon>mdi-alpha-x</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-text class="pt-4" style="text-align: center">
      <div v-if="!cargando_datos_vista">
        <v-col cols="12">
          <h1>Titular</h1>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" style="text-align: center" class="d-flex">
          <v-divider vertical></v-divider>
          <v-col cols="12">
            <h2 style="font-weight: normal">
              {{ nombre_titular
              }}<v-btn
                icon
                plain
                @click="
                  abrirModalAgregarAnexo(id_titular, documento_titular, true)
                "
                ><v-icon color="success" style="margin-bottom: 1px"
                  >mdi-plus</v-icon
                ></v-btn
              >
            </h2>
            <div v-if="archivos_titular.length > 0">
              <v-col
                cols="12"
                v-for="(archivo, index) in archivos_titular"
                :key="index"
              >
                <v-row class="align-center justify-center">
                  <v-icon class="mr-1" style="margin-top: 1px">{{
                    obtenerIconoExtension(archivo.extension)
                  }}</v-icon>
                  <a
                    ><h3>{{ archivo.nombre_archivo }}</h3></a
                  >
                  <v-btn
                    :disabled="validarDocumentoPrevisualizar(archivo.extension)"
                    icon
                    @click="previsualizarAnexo(archivo)"
                    ><v-icon>mdi-eye-outline</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    color="primary"
                    class="ml-n1"
                    @click="descargarAnexo(archivo.ruta_archivo)"
                    ><v-icon>mdi-arrow-down-bold-box-outline</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    color="red"
                    class="ml-n1"
                    :loading="archivo.eliminando"
                    @click="eliminarAnexoTitular(archivo.id, index)"
                    ><v-icon>mdi-trash-can</v-icon></v-btn
                  >
                </v-row>
              </v-col>
            </div>
            <div v-else>
              <h3 class="mt-4">No Registra</h3>
            </div>
          </v-col>
          <v-divider vertical></v-divider>
        </v-col>
        <v-col cols="12">
          <h1>Beneficiarios</h1>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" style="text-align: center">
          <v-row>
            <v-col
              cols="4"
              v-for="(beneficiario, index) in archivos_beneficiarios"
              :key="index"
            >
              <v-col cols="12">
                <h2 style="font-weight: normal">
                  {{ beneficiario.bennm1 + " " + beneficiario.benap1
                  }}<v-btn
                    icon
                    plain
                    @click="
                      abrirModalAgregarAnexo(
                        beneficiario.ID_Ben,
                        beneficiario.bendoc,
                        false
                      )
                    "
                    ><v-icon color="success" style="margin-bottom: 1px"
                      >mdi-plus</v-icon
                    ></v-btn
                  >
                </h2>

                <div v-if="beneficiario.anexos.length > 0">
                  <v-row
                    class="align-center justify-end mt-1 mb-2"
                    v-for="(archivo, index2) in beneficiario.anexos"
                    :key="index2"
                  >
                    <v-icon class="mr-1" style="margin-top: 1px">{{
                      obtenerIconoExtension(archivo.extension)
                    }}</v-icon>
                    <a
                      ><h3>{{ archivo.nombre_archivo }}</h3></a
                    >
                    <v-btn
                      :disabled="
                        validarDocumentoPrevisualizar(archivo.extension)
                      "
                      icon
                      @click="previsualizarAnexo(archivo)"
                      ><v-icon>mdi-eye-outline</v-icon></v-btn
                    >
                    <v-btn
                      icon
                      color="primary"
                      class="ml-n1"
                      @click="descargarAnexo(archivo.ruta_archivo)"
                      ><v-icon>mdi-arrow-down-bold-box-outline</v-icon></v-btn
                    >
                    <v-btn
                      icon
                      color="red"
                      class="ml-n1"
                      :loading="archivo.eliminando"
                      @click="
                        eliminarAnexoBeneficiario(archivo.id, index, index2)
                      "
                      ><v-icon>mdi-trash-can</v-icon></v-btn
                    >
                  </v-row>
                </div>
                <div v-else>
                  <h3 class="mt-4">No Registra</h3>
                </div>
              </v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-dialog v-model="modal_previsualizar" :width="ancho_embed">
            <v-card>
              <v-toolbar dark height="40" color="indigo darken-1">
                <v-spacer></v-spacer>
                <v-toolbar-title class="mr-n16">Previsualizar</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="red" small @click="modal_previsualizar = false"
                  ><v-icon>mdi-alpha-x</v-icon></v-btn
                >
              </v-toolbar>

              <embed
                contain
                v-if="modal_previsualizar"
                :src="ruta_archivo_embed"
                :type="tipo_archivo_embed"
                :width="ancho_embed"
                :height="altura_embed"
              />
            </v-card>
          </v-dialog>

          <v-dialog v-model="modal_agregar_anexo" width="400">
            <v-card>
              <v-toolbar dark height="40" color="indigo darken-1">
                <v-spacer></v-spacer>
                <v-toolbar-title class="mr-n16"
                  >Agregar Anexo {{ nombre_agregar_anexo }}</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  :disabled="guardando_anexos"
                  small
                  @click="modal_agregar_anexo = false"
                  ><v-icon>mdi-alpha-x</v-icon></v-btn
                >
              </v-toolbar>

              <v-card-text>
                <v-row>
                  <v-col class="mt-4">
                    <v-file-input
                      label="Selecciona los archivos"
                      multiple
                      chips
                      append-icon="mdi-paperclip"
                      v-model="anexos_nuevos"
                      prepend-icon=""
                      outlined
                      dense
                      color="primary"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="mt-n9 d-flex justify-end">
                <v-btn
                  color="success"
                  :loading="guardando_anexos"
                  @click="guardarAnexos"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </div>

      <v-col
        cols="12"
        v-else
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 200px;
        "
      >
        <v-spacer></v-spacer>
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-spacer></v-spacer>
      </v-col>
    </v-card-text>
  </v-card>
</template>
<script>
import contratosService from "../services/contratosService";
export default {
  props: ["id_grupo", "id_titular", "nombre_titular", "documento_titular"],
  data: () => ({
    anexos_nuevos: [],

    archivos_titular: [],
    archivos_beneficiarios: [],

    modal_previsualizar: false,
    ruta_archivo_embed: null,
    tipo_archivo_embed: null,
    ancho_embed: "400px",
    altura_embed: "500px",

    modal_agregar_anexo: false,
    nombre_agregar_anexo: null,
    id_agregar_anexo: null,
    es_titular: false,
    documento_anexo_agregar: null,
    guardando_anexos: false,

    cargando_datos_vista: false,
  }),
  created() {
    this.consultarAnexosContrato();
  },

  methods: {
    validarDocumentoPrevisualizar(nombre_extension) {
      if (this.obtenerIconoExtension(nombre_extension) == "mdi-file-question") {
        return true;
      }
      return false;
    },
    async consultarAnexosContrato() {
      this.cargando_datos_vista = true;
      await contratosService
        .consultarAnexosContrato(this.id_grupo)
        .then((response) => {
          if (response.data.anexos_titular.length > 0) {
            response.data.anexos_titular.forEach((anexo) => {
              Object.assign(anexo, { cargando_previsualizar: false });
              Object.assign(anexo, { descargando: false });
              Object.assign(anexo, { eliminando: false });
            });
          }
          this.archivos_titular = response.data.anexos_titular;

          if (response.data.beneficiarios.length > 0) {
            response.data.beneficiarios.forEach((ben) => {
              if (ben.anexos.length > 0) {
                ben.anexos.forEach((anexo) => {
                  Object.assign(anexo, { cargando_previsualizar: false });
                  Object.assign(anexo, { descargando: false });
                  Object.assign(anexo, { eliminando: false });
                });
              }
            });
          }
          this.archivos_beneficiarios = response.data.beneficiarios;
        });
      this.cargando_datos_vista = false;
    },
    abrirModalAgregarAnexo(id, documento, es_titular) {
      this.id_agregar_anexo = id;
      this.es_titular = es_titular;
      this.documento_anexo_agregar = documento;
      this.modal_agregar_anexo = true;
    },
    obtenerIconoExtension(nombre_extension) {
      let icono = "";
      switch (nombre_extension) {
        case "pdf":
          icono = "mdi-file-pdf-box";
          break;
        case "jpg":
          icono = "mdi-image-area";
          break;
        case "png":
          icono = "mdi-image-area";
          break;
        default:
          icono = "mdi-file-question";
          break;
      }

      return icono;
    },

    async descargarAnexo(ruta_archivo) {
      let array_extension = ruta_archivo.split("/");
      let nombre_archivo = array_extension[array_extension.length - 1];

      contratosService.descargarAnexo(ruta_archivo).then(async (response) => {
        //Se crea una direccion url del archivo con los datos recibidos de laravel excel
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));

        //Se crea en un elemento tipo link
        var fileLink = document.createElement("a");

        //Se le anexa a este link la direccion del excel
        fileLink.href = fileURL;

        //Se le asigna el atributo al link creado de "download" y va a descargar el archivo de esa direccion
        fileLink.setAttribute("download", nombre_archivo);
        //Se agrega este link al DOM
        document.body.appendChild(fileLink);

        //Se ejecuta el evento click en el link y se descarga automaticamente
        fileLink.click();
      });
    },

    async previsualizarAnexo(archivo) {
      this.tipo_archivo_embed = this.obtenerFormatoArchivoMostrar(
        archivo.extension
      );

      await contratosService
        .previsualizarAnexo(archivo.ruta_archivo)
        .then(async (response) => {
          this.ruta_archivo_embed =
            "data:" + this.tipo_archivo_embed + ";base64," + response.data.file;
        });

      if (archivo.extension != "pdf") {
        this.ancho_embed = "800px";
        this.altura_embed = "600px";
      } else {
        this.ancho_embed = "100%";
        this.altura_embed = "500px";
      }
      this.modal_previsualizar = true;
    },

    async guardarAnexos() {
      this.guardando_anexos = true;

      let data = new FormData();
      for (let index = 0; index < this.anexos_nuevos.length; index++) {
        data.append("file_" + index, this.anexos_nuevos[index]);
      }
      data.append("cantidad_anexos", this.anexos_nuevos.length);

      if (this.es_titular) {
        data.append("id_grupo", this.id_grupo);
        data.append("id_titular", this.id_titular);
        data.append("documento_titular", this.documento_titular);

        await contratosService
          .guardarAnexosTitular(data)
          .then((response) => {
            this.$swal
              .fire({
                title: "Anexos guardados",
                icon: "success",
                timer: 2000,
                position: "center",
                showConfirmButton: false,
                confirmButtonText: "OK",
              })
              .then(() => {
                this.modal_agregar_anexo = false;
                this.anexos_nuevos = [];
                this.consultarAnexosContrato();
              });
          })
          .catch(() => {
            this.$swal.fire({
              title: "Error,contactar administrador",
              icon: "info",
              position: "center",
              showConfirmButton: true,
              confirmButtonText: "OK",
            });
          });
      } else {
        data.append("id_grupo", this.id_grupo);
        data.append("id_beneficiario", this.id_agregar_anexo);
        data.append("documento_beneficiario", this.documento_anexo_agregar);

        await contratosService
          .guardarAnexosBeneficiario(data)
          .then((response) => {
            this.$swal
              .fire({
                title: "Anexos guardados",
                icon: "success",
                timer: 2000,
                position: "center",
                showConfirmButton: false,
                confirmButtonText: "OK",
              })
              .then(() => {
                this.modal_agregar_anexo = false;
                this.anexos_nuevos = [];
                this.consultarAnexosContrato();
              });
          })
          .catch(() => {
            this.$swal.fire({
              title: "Error,contactar administrador",
              icon: "info",
              position: "center",
              showConfirmButton: true,
              confirmButtonText: "OK",
            });
          });
      }

      this.guardando_anexos = false;
    },

    async eliminarAnexoBeneficiario(id_anexo, index_ben, index_anexo) {
      this.$swal
        .fire({
          title: "Estas seguro de eliminar el anexo?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.archivos_beneficiarios[index_ben].anexos[
              index_anexo
            ].eliminando = true;
            await contratosService
              .borrarAnexo(id_anexo)
              .then((response) => {
                this.$swal
                  .fire({
                    title: "Anexo eliminado",
                    icon: "success",
                    timer: 2000,
                    position: "center",
                    showConfirmButton: false,
                    confirmButtonText: "OK",
                  })
                  .then(() => {
                    this.consultarAnexosContrato();
                  });
              })
              .catch(() => {
                this.$swal.fire({
                  title: "Error,contactar administrador",
                  icon: "info",
                  position: "center",
                  showConfirmButton: true,
                  confirmButtonText: "OK",
                });
              });
            this.archivos_beneficiarios[index_ben].anexos[
              index_anexo
            ].eliminando = false;
          }
        });
    },

    async eliminarAnexoTitular(id_anexo, index_anexo) {
      this.$swal
        .fire({
          title: "Estas seguro de eliminar el anexo?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.archivos_titular[index_anexo].eliminando = true;
            await contratosService
              .borrarAnexo(id_anexo)
              .then((response) => {
                this.$swal
                  .fire({
                    title: "Anexo eliminado",
                    icon: "success",
                    timer: 2000,
                    position: "center",
                    showConfirmButton: false,
                    confirmButtonText: "OK",
                  })
                  .then(() => {
                    this.consultarAnexosContrato();
                  });
              })
              .catch(() => {
                this.$swal.fire({
                  title: "Error,contactar administrador",
                  icon: "info",
                  position: "center",
                  showConfirmButton: true,
                  confirmButtonText: "OK",
                });
              });
            this.archivos_titular[index_anexo].eliminando = false;
          }
        });
    },

    obtenerFormatoArchivoMostrar(extension_archivo) {
      let formato_archivo_mostrar = "";
      if (extension_archivo == "pdf") {
        formato_archivo_mostrar = "application/pdf";
      }

      if (extension_archivo == "jpg" || extension_archivo == "jpeg") {
        formato_archivo_mostrar = "image/jpeg";
      }

      if (extension_archivo == "png") {
        formato_archivo_mostrar = "image/png";
      }

      return formato_archivo_mostrar;
    },
  },
};
</script>
