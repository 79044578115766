<template>
    <v-container class="background">
      <v-col style="color: #fff">
        <span class="breadcrumb">Inicio</span>  /    <span class="breadcrumb" @click="irAContratos()"> Contratos </span>  / <span class="breadcrumb"> Agregar nuevo contrato</span>
      </v-col>
               <v-row>
                 <v-col cols="12" sm="" md="" lg="4" style="text-align:center">
                    <v-card elevation="0" color="white"  @click="irAPlanFamiliar()">
                        <v-card-title class="justify-center">Familiar</v-card-title>
                        <v-card-text>
                           <v-btn text >
                             <v-icon>
                               mdi-clipboard-text
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                 </v-col>
                 <v-col cols="12" sm="" md="" lg="4" style="text-align:center">
                    <v-card elevation="0" color="" >
                        <v-card-title class="justify-center">Colectivo</v-card-title>
                        <v-card-text>
                           <v-btn text >
                             <v-icon>
                               mdi-update
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="" md="" lg="4" style="text-align:center">
                    <v-card elevation="0" >
                        <v-card-title class="justify-center">Area Protegida</v-card-title>
                        <v-card-text>
                           <v-btn text>
                             <v-icon>
                               mdi-file-export
                             </v-icon>
                           </v-btn>
                        </v-card-text>
                    </v-card>
                 </v-col>
      
               </v-row>
         

    </v-container>
</template>

<script>


  export default {
    name: 'Home',

    components: {
    
    },
    methods: {
      irAPlanFamiliar(){
        this.$router.push({ path: 'familiar' })
      },
      irAHome(){
         this.$router.push({ path: '/' })
     }, 
        irAContratos(){
         this.$router.push({ path: '/contratos' })
     }, 
    }
  }
</script>
<style scoped>
.background{
    background: rgb(34, 78, 201) !important;
}

.breadcrumb:hover{
    padding: 1px 0px 1px 0px;
    border-radius: 5px;
    background: rgb(70, 108, 214) !important; 
}

</style>