<template>
  <div class="container fluid">
    <Datatable></Datatable>
  </div>
</template>

<script>
import Datatable from "@/components/DatatableContratos.vue"

export default {
  name: "ContratosGuardados",
  data: () => ({
 
    
  }),
  components: {
    Datatable
  },

};
</script>
