<template>
  <v-card>
    <v-toolbar dark height="40" color="indigo darken-1">
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-n16">Estado Contrato</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="red" small @click="$emit('cerrar-modal')"
        ><v-icon>mdi-alpha-x</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-text v-if="!cargando_vista">
      <v-col cols="12">
        <label for="estado_revision">Estado Revisión</label>
        <v-select
          id="estado_revision"
          :items="estados_revision"
          @change="cambioEstadoRevision"
          item-text="estado_revision"
          item-value="id"
          outlined
          dense
          v-model="estado_revision"
        >
        </v-select>
      </v-col>
      <v-col cols="12" v-if="estado_revision == 1" class="mt-n8">
        <label for="estado_aprobacion">Estado Aprobación</label>
        <v-select
          id="estado_aprobacion"
          :items="estados_aprobacion"
          outlined
          dense
          v-model="estado_aprobacion"
        >
        </v-select>
      </v-col>
      <v-col cols="12" v-if="estado_aprobacion === 0" class="mt-n8">
        <label for="motivo_desaprobacion"
          >Motivo Desaprobación<v-btn
            v-show="agregar_motivo_desaprobacion == false"
            class="mt-n1"
            x-small
            text
            color="success"
            @click="agregar_motivo_desaprobacion = true"
            >[+ AGREGAR ]</v-btn
          >
          <v-btn
            v-show="agregar_motivo_desaprobacion"
            :disabled="
              nuevo_motivo_desaprobacion.replace(/ /g, '') == '' ||
              agregando_motivo_desaprobacion
            "
            class="mt-n1"
            x-small
            text
            color="success"
            @click="agregarNuevoMotivoDesaprobacion()"
            >[+ GUARDAR ]</v-btn
          >
          <v-btn
            v-show="agregar_motivo_desaprobacion"
            :disabled="agregando_motivo_desaprobacion"
            class="mt-n1"
            x-small
            text
            color="red"
            @click="agregar_motivo_desaprobacion = false"
            >[ CANCELAR ]</v-btn
          ></label
        >
        <v-select
          v-if="!agregar_motivo_desaprobacion"
          id="motivo_desaprobacion"
          :items="motivos_desaprobacion"
          item-text="motivo_desaprobacion"
          item-value="id"
          outlined
          dense
          v-model="motivo_desaprobacion"
        >
        </v-select>
        <v-text-field
          v-else
          outlined
          dense
          v-model="nuevo_motivo_desaprobacion"
          :loading="agregando_motivo_desaprobacion"
        >
        </v-text-field>
      </v-col>
    </v-card-text>
    <v-card-actions v-if="!cargando_vista">
      <v-row class="mt-n12 mb-1">
        <v-col class="d-flex justify-end">
          <v-btn
            color="primary"
            :loading="guardando_estado_contrato"
            :disabled="!validarGuardarEstado"
            @click="guardarEstadoContrato"
            >Guardar</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
    <v-col
      cols="12"
      v-else
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
      "
    >
      <v-spacer></v-spacer>
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <v-spacer></v-spacer>
    </v-col>
  </v-card>
</template>
<script>
import contratosService from "../services/contratosService";

export default {
  props: [
    "id_grupo",
    "estado_revision_prop",
    "estado_aprobacion_prop",
    "motivo_desaprobacion_prop",
    "estados_revision",
  ],
  data: () => ({
    estados_aprobacion: [
      { text: "Aprobado", value: 1 },
      { text: "Desaprobado", value: 0 },
    ],
    motivos_desaprobacion: [],

    estado_aprobacion: 1,
    estado_revision: 3,

    agregar_motivo_desaprobacion: false,
    agregando_motivo_desaprobacion: false,
    nuevo_motivo_desaprobacion: "",
    motivo_desaprobacion: null,

    guardando_estado_contrato: false,

    cargando_vista: false,
  }),
  async created() {
    this.cargando_vista = true;
    this.estado_revision = this.estado_revision_prop;
    this.estado_aprobacion = this.estado_aprobacion_prop;
    this.motivo_desaprobacion = this.motivo_desaprobacion_prop;
    await contratosService.motivosDesaprobacion().then((response) => {
      this.motivos_desaprobacion = response.data;
    });
    this.cargando_vista = false;
  },
  computed: {
    validarGuardarEstado() {
      if (this.estado_revision === 1) {
        if (this.estado_aprobacion != null) {
          if (this.estado_aprobacion === 0) {
            if (this.motivo_desaprobacion === null) {
              return false;
            }
            return true;
          }
          return true;
        }
        return false;
      }
      return true;
    },
  },
  methods: {
    cambioEstadoRevision() {
      if (this.estado_revision != 1) {
        this.estado_aprobacion = null;
        this.motivo_desaprobacion = null;
      }
    },
    async agregarNuevoMotivoDesaprobacion() {
      this.agregando_motivo_desaprobacion = true;
      await contratosService
        .agregarMotivoDesaprobacion({
          nuevo_motivo: this.nuevo_motivo_desaprobacion,
        })
        .then((response) => {
          this.motivo_desaprobacion = response.data.id;
          this.motivos_desaprobacion.push(response.data);
          this.agregar_motivo_desaprobacion = false;
          this.nuevo_motivo_desaprobacion = "";
        });
      this.agregando_motivo_desaprobacion = false;
    },
    async guardarEstadoContrato() {
      this.guardando_estado_contrato = true;

      await contratosService
        .guardarEstadoContrato({
          estado_revision: this.estado_revision,
          estado_aprobacion: this.estado_aprobacion,
          motivo_desaprobacion: this.motivo_desaprobacion,
          id_grupo: this.id_grupo,
        })
        .then(() => {
          this.$swal
            .fire({
              title: "Estado Guardado",
              icon: "success",
              timer: 2000,
              position: "center",
              showConfirmButton: false,
              confirmButtonText: "OK",
            })
            .then(() => {
              let estados_revision = JSON.parse(
                JSON.stringify(this.estados_revision)
              );
              let motivo_desaprobacion_texto =
                this.motivos_desaprobacion.filter(
                  (element) => element.id === this.motivo_desaprobacion
                );
              let estado_revision_texto = estados_revision.filter(
                (element) => element.id === this.estado_revision
              );

              this.$emit("guardar-estado-contrato", {
                estado_revision: this.estado_revision,
                estado_revision_texto:
                  estado_revision_texto.length > 0
                    ? estado_revision_texto[0].estado_revision
                    : null,
                color_estado_revision:
                  estado_revision_texto.length > 0
                    ? estado_revision_texto[0].color_estado_revision
                    : null,
                estado_aprobacion:
                  this.estado_revision != 1 ? null : this.estado_aprobacion,
                motivo_desaprobacion:
                  this.estado_revision != 1
                    ? null
                    : this.estado_aprobacion == 0
                    ? this.motivo_desaprobacion
                    : null,
                motivo_desaprobacion_texto:
                  motivo_desaprobacion_texto.length > 0
                    ? motivo_desaprobacion_texto[0].motivo_desaprobacion
                    : null,
                id_grupo: this.id_grupo,
              });
              this.$emit("cerrar-modal");
            });
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al guardar Estado ",
            text: "Contactar Admin",
            icon: "error",
            timer: 2000,
            position: "center",
            showConfirmButton: false,
            confirmButtonText: "OK",
          });
        });

      this.guardando_estado_contrato = false;
    },
  },
};
</script>
<style></style>
