<template>
  <v-card>
    <v-toolbar dark height="40" color="indigo darken-1">
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-n16">Beneficiarios</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="red" small @click="$emit('cerrar-modal')"
        ><v-icon>mdi-alpha-x</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-text>
      <v-data-table
        class="mt-4"
        v-if="!cargando_beneficiarios"
        :headers="headers"
        :items="items_datatable"
      >
    <template v-slot:[`item.nombre`]="{item}">
        {{ retornarNombreContenado(item) }}
    </template>
    
    </v-data-table>
      <v-col
        cols="12"
        v-else
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 200px;
        "
      >
        <v-spacer></v-spacer>
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-spacer></v-spacer>
      </v-col>
    </v-card-text>
  </v-card>
</template>
<script>
import contratosService from "../services/contratosService";

export default {
  props: ["id_grupo"],
  data: () => ({
    items_datatable: [],
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Tipo Documento", value: "tipodoc" },
      { text: "Documento", value: "bendoc" },
      { text: "EPS", value: "epsnom" },
      { text: "Correo", value: "mail" },
      { text: "Celular", value: "bencel" },
      { text: "Direccion", value: "direccion_completa" },
    ],
    cargando_beneficiarios: false,
  }),
  async created() {
    this.cargando_beneficiarios = true;
    await contratosService
      .consultarBeneficiariosContrato(this.id_grupo)
      .then((response) => {
        this.items_datatable = response.data;
      });
    this.cargando_beneficiarios = false;
  },
  methods:{
    retornarNombreContenado(item){
        return item.bennm1 + ' '+ (item.bennm2 == null?'':item.bennm2) + item.benap1 + ' '+ (item.benap2 == null?'':item.benap2);
    }
  }
};
</script>
