var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container fluid"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"blue darken-3","height":"40"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('p',{staticClass:"mt-4",staticStyle:{"font-size":"14px"}},[_vm._v("Información del responsable de pago - Titular")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Año","outlined":"","dense":"","hide-details":"","type":"number"},model:{value:(_vm.filtroPorAño),callback:function ($$v) {_vm.filtroPorAño=$$v},expression:"filtroPorAño"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Buscar","outlined":"","dense":"","hide-details":""},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.infoDatatable,"search":_vm.buscar,"sort-by":"calories","footer-props":{
    showFirstLastPage: true,
    'items-per-page-text': 'Filas por pagina',
  },"header-props":{
    'sort-by-text': 'Ordena por:',
  }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.eliminar(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }