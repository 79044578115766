<template>
  <div class="container fluid">
    <v-row>
      <v-col cols="12">
        <v-card>
               <v-toolbar dark color="blue darken-3" height="40">
                     <router-link to="/"><v-icon>mdi-arrow-left</v-icon></router-link>
                     
                     <v-spacer></v-spacer>
                         <!-- <v-toolbar-title>Información del responsable de pago - Titular</v-toolbar-title> -->
                         <p class="mt-4" style="font-size:14px">Información del responsable de pago - Titular</p>
                     <v-spacer></v-spacer>
                 </v-toolbar>

            <v-card-text>

    <v-row class="mb-1">
      <v-col cols="12" sm="6">
          <v-text-field placeholder="Año" v-model="filtroPorAño" outlined dense hide-details type="number"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
          <v-text-field placeholder="Buscar" v-model="buscar" outlined dense hide-details></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
    :headers="headers"
    :items="infoDatatable"
    :search="buscar"
    sort-by="calories"
    class="elevation-1"
    :footer-props="{
      showFirstLastPage: true,
      'items-per-page-text': 'Filas por pagina',
    }"
    :header-props="{
      'sort-by-text': 'Ordena por:',
    }"
  >
    <!-- <template v-slot:top>
      <v-toolbar
        flat
      >
       
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.fat"
                      label="Fat (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.carbs"
                      label="Carbs (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.protein"
                      label="Protein (g)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template> -->
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editar(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="eliminar(item)"
      >
        mdi-delete
      </v-icon>
    </template>

  </v-data-table>
            </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  name: "Datatable",
  data: () => ({
      buscar: '',
      filtroPorAño: 0,
      dialog: false,

      dialogDelete: false,
      infoDatatable: [
          {
              nombre: 'Mitchel'
          },
           {
              nombre: 'Karla'
          },
           {
              nombre: 'Danika'
          },
           {
              nombre: 'Kevin'
          },
      ],
    headers: [
      { text: "", values: "moda"}, 
      { text: "Nombre", value: "nombre" },
      { text: "N beneficiario", value: "n_beneficiario" },
      { text: "Tarifa Individual", value: "tarifaIndividual" },
      { text: "Tarifa Total", value: "tarifaTotal" },
      { text: "Validación", value: "validacion" },
      { text: "Estado Contrato", value: "estadoContrato" },
      { text: 'Actions', value: 'actions', sortable: false },
      
    ],
    
  }),
  components: {},
  methods: {
      navegarAFamiliar(){
          this.$router.push({ path: 'familiar' })
      },
      editar(){
        alert('editando')
      },
      eliminar(){
        alert('Eliminando')
      }

  }
};
</script>
